<template>
  <div class="page" style="display: flex;flex-direction: column">
    <LandingHeader/>
    <section id="privacy" class="guest" style="flex: 1 0 auto;">
      <div class="container">
        <div class="guest__inner row" style="justify-content: center;">
          <h2 class="title title_guest" style="margin:0 0 30px 0;text-align:center">Privacy & Legacy</h2>
        </div>
      </div>
    </section>
    <LandingFooter/>
  </div>
</template>

<script>
export default {
  name: "LandingPrivacy",
  components: {
    LandingHeader:() => import("@/components/Landing/LandingHeader"),
    LandingFooter:() => import("@/components/Landing/LandingFooter")
  },
  mounted() {
    document.title = 'Use cases';
  }
}
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

span,
time,
label {
  display: inline-block;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.5;
}

button {
  cursor: pointer;
}
@import "@/scss/landing/style.scss";
</style>
